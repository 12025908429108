.status-bar {
	height: 40px;
	overflow: hidden;
	white-space: nowrap;
	vertical-align: middle;
	line-height: 40px;
	padding-left: 10px;
	color: grey;
}

.status-bar-error {
	color: #0000FF; 
}
