.my-text-danger {
	color: #0000FF !important;
	font-weight: bold;
}

.my-text-success {
	color: gray !important;
	font-weight: bold;
}


.my-btn-danger {
	background-color: #CCCCFF;
	border-color: #0000FF;
	font-weight: bold;
	color: #0000FF;
}

.my-btn-danger:hover {
	background-color: #E5E5FF;
	color: #0000FF;
}

.my-btn-danger.disabled, .my-btn-danger:disabled {
}
