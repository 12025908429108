.logo {
	stroke: black;
	stroke-width: 8px;
	fill: none;
	stroke-linejoin: round;
}

.logo-border {
	fill: yellow;
}
