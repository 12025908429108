.fanorona {
	font-weight: bold;
	text-align: center;
	color: yellow;
	text-decoration: underline;
	text-shadow: 1px 1px 2px black;
}

.subtitle {
	text-align: center;
}

.subtitle > a {
	font-weight: bold;
	color: yellow;
	text-shadow: -1px 0 gray, 0 1px gray, 1px 0 gray, 0 -1px gray;
}

.subtitle > a:hover {
	text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
	text-decoration: none;
}

.logo-home {
	max-width: 350px;
	margin: 0 auto;
}

