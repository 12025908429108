.my-container {
	margin-top: 60px;
}

.big-title {
	background-color: #FFFF00;
	padding: 10px;
	display: inline-block;
	border-radius: 4px;
}

.important {
	color: black;
}

.my-btn-primary {
	color: black;
	background-color: yellow;
	border-color: black;
	font-weight: bold;
}

.my-btn-primary:hover {
	background-color: #FFFFCC;
}

.my-btn-primary.disabled, .my-btn-primary:disabled {
	color: gray;
	background-color: yellow;
	border-color: gray;
}
